<script setup>
// import TextViewerVue from '@/components/TextViewer.vue'
import introduce from '@/assets/images/introduce(2).jpg'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const content = ref('')
const contentEn = ref('')
const route = useRoute()

const loadData = async (id) => {
  const { default: data } = await import('@/data/content')
  return data.items.find((item) => item.contentId === id)
}

onMounted(async () => {
  const data = await loadData(route.meta.id)
  content.value = data.content
  contentEn.value = data.contentEn
})
</script>

<template>
  <div>
    <img :src="introduce" alt="" />
  </div>
  <!-- <h3>TextViewer Component</h3>
  <TextViewerVue v-if="content" :propsData="content"></TextViewerVue>
  <TextViewerVue v-if="contentEn" :propsData="contentEn"></TextViewerVue> -->
</template>

<style scoped>
* {
  text-align: left;
}

div {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}
</style>
